import React, { useState, useRef } from 'react';
import {
    FileProtectOutlined,
    HomeOutlined,
    MobileOutlined,
    ShopOutlined,
    TrophyOutlined,
    UserAddOutlined,
    WhatsAppOutlined,
    SoundOutlined,
    AudioMutedOutlined,
    MutedOutlined
} from '@ant-design/icons';
import { header } from '../../portfolio';
import './Header.css';
import Navbar from "../Navbar/Navbar";
import DevIcon from "./DevIcone";

const Header = () => {
    const { homepage, title } = header;
    const [isMuted, setIsMuted] = useState(true);
    const audioRef = useRef(null);

    const toggleMute = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isMuted) {
                audio.muted = false;
                setIsMuted(false);
            } else {
                audio.muted = true;
                setIsMuted(true);
            }
        }
    };

    return (
        <header className='header center'>
            {/*<audio ref={audioRef} src="https://s4.uupload.ir/filelink/lp4ImRbJqpJf_66d7ced835/khalid_new_normal_vtpz.mp3"*/}
            {/*<audio ref={audioRef} src="https://dl6.download1music.ir/Music/Without-Words/0bikalam%20download1music.ir%20(10).mp3"*/}
            <audio ref={audioRef} src="https://malekpoursuccess.com/wp-content/uploads/2021/12/Ali-toygar-roots2.mp3"
                   autoPlay loop muted />
            <div className='header-left'>
                {homepage ? (
                    <a href={homepage} className='link'>
                        <HomeOutlined/>
                    </a>
                ) : (
                    title
                )}
                {/*<span className='space'/>*/}
                {/*<a href="tel:+989122273119" className='link'>*/}
                {/*    <MobileOutlined/>*/}
                {/*</a>*/}
                <span className='space'/>
                <button onClick={toggleMute} className='link' title="پخش موزیک">
                    {isMuted ? <MutedOutlined/> : <SoundOutlined/>}
                </button>
                <span className='space'/>
                <a href="/signup" className='link' title="ثبت‌نام">
                    <UserAddOutlined/>
                </a>
                <span className='space'/>
                <a href="https://drive.google.com/file/d/1aJW5nK-5ZbUq5BKZH1Zf1wG1t_bR3fZJ/view?usp=sharing"
                   className='link'
                   title="دانلود قرارداد کاری"
                   target="_blank"
                   rel="noopener noreferrer">
                    <FileProtectOutlined/>
                </a>
                <span className='space'/>
                <a href="/shop" className='link' title="فروشگاه">
                    <ShopOutlined style={{color: '#ff9900'}}/>
                </a>
                <span className='space'/>
                <a href="https://wa.me/989122273119" className='link' target="_blank" rel="noopener noreferrer">
                    <WhatsAppOutlined style={{color: '#25D366'}}/>
                </a>

                <span className='space'/>
                <a href="/question" className='link' title="استعدادیابی">
                    <TrophyOutlined className='animated-icon' style={{color: '#FFD700'}}/>
                </a>
            </div>

            <div className='header-center'>
                <a href="/training-path" title="مسیر آموزش" className='icon-container'>
                    <DevIcon/>
                    <span className="lalezarI">کوچینگ</span>
                </a>
            </div>

            <div className='header-right'>
                <Navbar/>
            </div>
        </header>
    );
};

export default Header;
