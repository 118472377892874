// SearchResults.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderShop from '../HeaderShop/HeaderShop';
import ProductList from '../Product/ProductList';
import { allProducts } from '../../../productsStore';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SearchResults = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const searchText = query.get('query') || '';
    const filteredProducts = allProducts.filter(
        (product) =>
            product.title.includes(searchText) ||
            product.description.includes(searchText)
    );

    const handleItemClick = (item) => {
        navigate(`/product/${item.id}`);
    };

    return (
        <div>
            <HeaderShop products={allProducts} />
            <ProductList products={filteredProducts} onItemClick={handleItemClick} />
        </div>
    );
};

export default SearchResults;
