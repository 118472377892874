// ProductDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Rate, Button } from 'antd';
import HeaderShop from '../HeaderShop/HeaderShop';
import { allProducts } from '../../../productsStore';
import './ProductDetail.css';

const ProductDetail = () => {
    const { productId } = useParams();
    const product = allProducts.find((p) => p.id === parseInt(productId));

    if (!product) return <p>محصول یافت نشد</p>;

    return (
        <>
            <HeaderShop products={allProducts} />
            <div className="product-detail">
                <Card>
                    <img alt={product.title} src={product.image} />
                    <h2>{product.title}</h2>
                    <p>{product.description}</p>
                    <p>قیمت: {product.price} تومان</p>
                    <p>تخفیف: {product.discount}</p>
                    <Rate disabled defaultValue={product.rating} />
                    <Button type="primary">افزودن به سبد خرید</Button>
                </Card>
            </div>
        </>
    );
};

export default ProductDetail;
