import {Typography} from "antd";
import {useContext} from 'react'
import {useNavigate} from "react-router-dom";
import {ThemeContext} from '../../contexts/theme'
import Header from '../Header/Header'
import About from '../About/About'
import Projects from '../Projects/Projects'
import Skills from '../Skills/Skills'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'
import './Home.css'
import CarouselFerris from "../Carousel/CarouselFerris";
import AlertMessage from "../News/AlertMessage";

const {Text} = Typography;

const Home = () => {
    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};
    const navigate = useNavigate();

    return (
        <div id='top' className={`${themeName} app`}>
            {/* <Affix style={{position: 'additions'}}> */}
            <Header/>
            {/* </Affix> */}
            <main>
                {/* <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p> */}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>
                    {/* <p className="bNazanintd"> لوگو </p> */}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <p className="bNazanintdP"
                       onClick={() => navigate('/uni')}>
                        {/* <Text mark className="animatedColorChange">پورتال دانشجویی</Text> */}
                        <Text className="waveText">پورتال دانشجویی</Text>
                    </p>
                </div>
                <AlertMessage/>

                {/* <Link to="/software">software</Link> */}
                <CarouselFerris/>
                <About/>
                <Projects/>
                <Skills/>
                <Contact/>
            </main>
            <ScrollToTop/>
            <Footer/>
        </div>
    )
}

export default Home
