import React from 'react';
import './ProductList.css';

const ProductList = ({ products, onItemClick }) => {
    return (
        <div className="product-container">
            <div className="product-table">
                {products.map((product) => (
                    <div
                        key={product.id}
                        className="product-cell"
                        onClick={() => onItemClick(product)}
                    >
                        <img src={product.image} alt={product.title} className="product-image" />
                        <div className="product-info">
                            <h3>{product.title} - <span className="description">{product.description}</span></h3>
                            <div className="product-rating">
                                <span>{product.rating.toFixed(1)}</span> {/* عدد بین ۱ تا ۱۰ اعشاری برای مشخص کردن امتیاز */}
                                <i className="fa fa-star"/> {/* ستاره برای ریتینگ */}
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <p className="product-price">{product.price} تومان </p> {/* نمایش قیمت با تومان قبل از مقدار و قرارگیری در سمت چپ پایین */}
                    </div>
                ))}
            </div>
            <div className="filter-container">
                {/* محتوای کامپوننت فیلتر */}
            </div>
        </div>
    );
};

export default ProductList;
