import React, {useContext} from 'react';
import {Helmet} from 'react-helmet';
import './Blog.css';
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Header from "../Header/Header";
import {ThemeContext} from "../../contexts/theme";
import {Image} from "antd";

const Immigration = () => {

    const [{themeName}] = useContext(ThemeContext);
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    const articles = [
        {title: "مهاجرت با برنامه‌نویسی", link: "/immigration"},
        {title: "کوچینگ برنامه‌نویسی", link: "/coachingblog"},
        // { title: "مقاله سوم", link: "/article3" },
        // { title: "مقاله چهارم", link: "/article4" }
    ];

    return (
        <div className={`${themeName} app`}>
            <Helmet>
                <title>مهاجرت با برنامه‌نویسی: فرصتی بی‌نظیر برای توسعه‌دهندگان</title>
                <meta name="description"
                      content="برنامه‌نویسی یکی از پرطرفدارترین مهارت‌ها در دنیای امروز است که به توسعه‌دهندگان این امکان را می‌دهد تا با فرصت‌های شغلی بین‌المللی روبه‌رو شوند و زندگی جدیدی را در کشورهای دیگر آغاز کنند."/>
            </Helmet>
            <Header/>
            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>
                <Image
                    src="https://www.fragomen.com/a/web/6ZTsaunMsBqbL1gJhKnbQE/9Fbvtj/the-eb-5-immigrant-investor-program-what-to-expect-under-the-trump-20-administration-header.jpg"
                    alt="مهاجرت با برنامه‌نویسی"
                    className="header-image"
                    preview={false}
                />
                <div className="container">
                    <div className="sidebar">
                        <h3 className="lalezar">لیست مقالات</h3>
                        <ul>
                            {articles.map((article, index) => (
                                <li key={index} className="bNazanintdrDBlog vazirablog">
                                    <a href={article.link}>{article.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="content">
                        <article>
                            <h1 className="bTitrImm">مهاجرت با برنامه‌نویسی<a className="bNazanintd"
                                                                              href="/immigration"> (فرصتی بی‌نظیر برای
                                توسعه‌دهندگان) </a></h1>
                            <p className="vazirablog">برنامه‌نویسی یکی از پرطرفدارترین مهارت‌ها در دنیای امروز است که به
                                توسعه‌دهندگان این امکان را می‌دهد تا با فرصت‌های شغلی بین‌المللی روبه‌رو شوند و زندگی
                                جدیدی را در کشورهای دیگر آغاز کنند. در این مقاله، به بررسی مزایا و نکات کلیدی مهاجرت با
                                برنامه‌نویسی خواهیم پرداخت.</p>

                            <h2 className="bTitrBlog">مزایای مهاجرت با برنامه‌نویسی</h2>
                            <ul>
                                <li className="vazirablog">تقاضای بالا برای توسعه‌دهندگان: کشورهای مختلف به دنبال
                                    متخصصان برنامه‌نویسی با مهارت‌های بالا هستند. این تقاضا برای توسعه‌دهندگان به معنای
                                    فرصت‌های شغلی بهتر و شرایط کاری مطلوب‌تر است.
                                </li>
                                <li className="vazirablog">درآمد بالا: برنامه‌نویسان معمولاً درآمد بالایی دارند و این
                                    امر می‌تواند به بهبود کیفیت زندگی و رفاه مالی کمک کند.
                                </li>
                                <li className="vazirablog">فرصت‌های رشد و پیشرفت شغلی: مهاجرت به کشورهای پیشرفته
                                    می‌تواند فرصت‌های رشد و پیشرفت شغلی بیشتری را برای برنامه‌نویسان فراهم کند.
                                </li>
                                <li className="vazirablog">تجربه‌ی زندگی در فرهنگ‌های مختلف: مهاجرت به کشورهای دیگر به
                                    شما این امکان را می‌دهد تا با فرهنگ‌ها و آداب و رسوم مختلف آشنا شوید و تجربه‌ی زندگی
                                    متنوعی داشته باشید.
                                </li>
                            </ul>

                            <h2 className="bTitrBlog">نکات کلیدی برای مهاجرت با برنامه‌نویسی</h2>
                            <ul>
                                <li className="vazirablog">تحقیق در مورد بازار کار: پیش از مهاجرت، تحقیق کنید که در کدام
                                    کشورها تقاضا برای مهارت‌های برنامه‌نویسی شما بالاست و کدام شرکت‌ها به دنبال استخدام
                                    توسعه‌دهندگان هستند.
                                </li>
                                <li className="vazirablog">آماده‌سازی مدارک و ویزا: اطمینان حاصل کنید که تمامی مدارک
                                    مورد نیاز برای درخواست ویزا و مهاجرت آماده است. همچنین، ممکن است نیاز به مدارکی
                                    مانند رزومه، نمونه‌کارها و گواهی‌های تحصیلی داشته باشید.
                                </li>
                                <li className="vazirablog">تسلط به زبان کشور مقصد: تسلط به زبان کشور مقصد می‌تواند
                                    فرآیند مهاجرت و تطبیق با محیط جدید را آسان‌تر کند.
                                </li>
                                <li className="vazirablog">برنامه‌ریزی مالی: پیش از مهاجرت، برنامه‌ریزی مالی دقیقی داشته
                                    باشید تا از مشکلات مالی در کشور مقصد جلوگیری کنید.
                                </li>
                                <li className="vazirablog">شبکه‌سازی و ارتباطات: با برنامه‌نویسان و متخصصان محلی در کشور
                                    مقصد ارتباط برقرار کنید. این کار می‌تواند به شما در پیدا کردن شغل و تطبیق با محیط
                                    جدید کمک کند.
                                </li>
                            </ul>

                            <h2 className="bTitrBlog">نتیجه‌گیری</h2>
                            <p className="vazirablog">مهاجرت از طریق برنامه‌نویسی فرصتی بی‌نظیر برای توسعه‌دهندگان فراهم
                                می‌کند تا در کشورهای مختلف تجربه‌های جدیدی کسب کرده و در مسیر شغلی خود پیشرفت کنند. با
                                تحقیقات دقیق و آماده‌سازی مناسب که
                                گروه آموزشی دکتر اسماعیل صادقی
                                ارائه می‌دهد، می‌توانید به شکلی بهینه از این فرصت بهره‌مند شده و فرآیند مهاجرت را به
                                بهترین نحو طی کنید.</p>
                        </article>
                        <br/>
                        <br/>
                        <nav>
                            <ul>
                                <li className="bNazanintdrDBlog"><a href="/aboutme">درباره ما</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </main>
            <ScrollToTop/>
            <Footer/>
        </div>
    );
};

export default Immigration;
