import Icon from "antd/es/icon";
import Typewriter from "typewriter-effect";
import {
    GithubFilled,
    InstagramFilled,
    LinkedinFilled,
    TwitterOutlined,
    FacebookOutlined,
} from '@ant-design/icons';
import {about, aboutpersion, contact} from '../../portfolio'
import './About.css'
import TelSVG from '../../contexts/img/telegram.svg';

// eslint-disable-next-line react/jsx-props-no-spreading
const TelIcon = props => <Icon component={TelSVG} {...props} />;

const About = () => {
    const {name, role, description, resume, social, animated, aptitude} = about
    const {titled, namepersion, lastname, prefix, suffix} = aboutpersion
    const state = {date: new Date()}
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};

    const path = process.env.PUBLIC_URL;

    return (
        <div className='about center'>
            {name && (
                <h2 style={{fontSize: 25}}>
                    {/* <img src={esmaeil} alt={titled}/> */}
                    <span className='about__name nastaliq'>{prefix}</span>
                    <span className='about__name shekastehnamepersion'>{namepersion}</span>
                    <span className='about__name shekastehlastname'>{lastname}</span>
                    {/* <span className='about__name dimaShekasteh'>{suffix}</span> */}
                    <br/>
                    <span className='about__name__title bNazanint'>{titled}</span>
                </h2>
            )}

            {role && <h4 className='about__role' style={{fontSize: 13}}>
                <Typewriter
                    options={{
                        strings: [
                            animated.first,
                            animated.second,
                            animated.third,
                        ],
                        autoStart: true,
                        deleteSpeed: 20,
                        pauseFor: 10000,
                        loop: true,

                    }}
                />
            </h4>}

            {/* <p className='about__desc bNazaninF'>{description}</p> */}
            <p className='about__desc vazirablog'>{description}</p>

            <div className='about__contact center'>
                {/* {resume && ( */}
                {/*     <a href={resume}> */}
                {/*                 <span type='button' className='btn btn--outline vazirlight'> */}
                {/*                   رزومه کاری */}
                {/*                 </span> */}
                {/*     </a> */}
                {/* )} */}

                {aptitude && (
                    <a href={aptitude}>
                                <span type='button' className='btn btn--outline vazirlight'>

                                    استعدادیابی
                                    {/* کوییز برنامه‌نویسی */}
                                </span>
                    </a>
                )}

                {resume && (
                    <a href="/training-path">
                    <span type='button' className='btn btn--outline vazirlight'>
                        کوچینگ
                    </span>
                    </a>
                )}

                {social && (
                    <>
                        {/* {social.yahoo && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.yahoo}`} */}
                        {/*         aria-label='yahoo' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <YahooOutlined style={{fontSize: '180%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {/* {social.yahoo && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.yahoo}`} */}
                        {/*         aria-label='yahoo' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <GoogleCircleFilled style={{fontSize: '180%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}
                        {/* {social.yahoo && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.yahoo}`} */}
                        {/*         aria-label='yahoo' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <GoogleOutlined style={{fontSize: '180%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}


                        {/* {social.telegram && ( */}
                        {/*     <a */}
                        {/*         href={social.telegram} */}
                        {/*         aria-label='telegram' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*        /!* <img src={TelIcon} style={{width: 25, height: 34}} alt=""/> *!/ */}
                        {/*     <TelIcon style={{fontSize: '150%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}


                        {/* {social.skype && ( */}
                        {/*     <a */}
                        {/*         href={social.skype} */}
                        {/*         aria-label='skype' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <SkypeFilled style={{fontSize: '170%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {/* {social.whatsApp && ( */}
                        {/*     <a */}
                        {/*         href={social.whatsApp} */}
                        {/*         aria-label='whatsApp' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <WhatsAppOutlined style={{fontSize: '150%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {/* {social.google && ( */}
                        {/*     <a */}
                        {/*         href={`mailto:${contact.email}`} */}
                        {/*         aria-label='google' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         /!* eslint-disable-next-line react/jsx-no-undef *!/ */}
                        {/*         <GoogleCircleFilled style={{fontSize: '170%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {social.github && (
                            <a
                                href={social.github}
                                aria-label='github'
                                className='link link--icon'
                            >
                                <GithubFilled style={{fontSize: '150%'}}/>
                            </a>
                        )}


                        {/* {social.apple && ( */}
                        {/*     <a */}
                        {/*         href={social.apple} */}
                        {/*         aria-label='apple' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <AppleOutlined style={{fontSize: '180%', marginBottom: 9}}/> */}
                        {/*     </a> */}
                        {/* )} */}


                        {social.linkedin && (
                            <a
                                href={social.linkedin}
                                aria-label='linkedin'
                                className='link link--icon'
                            >
                                <LinkedinFilled style={{fontSize: '150%'}}/>
                            </a>
                        )}

                        {/* {social.facebook && ( */}
                        {/*     <a */}
                        {/*         href={social.facebook} */}
                        {/*         aria-label='facebook' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <FacebookOutlined style={{fontSize: '150%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                        {social.instagram && (
                            <a
                                href={social.instagram}
                                aria-label='instagram'
                                className='link link--icon'
                            >
                                <InstagramFilled style={{fontSize: '150%'}}/>
                            </a>
                        )}


                        {/* {social.twitter && ( */}
                        {/*     <a */}
                        {/*         href={social.twitter} */}
                        {/*         aria-label='twitter' */}
                        {/*         className='link link--icon' */}
                        {/*     > */}
                        {/*         <TwitterOutlined style={{fontSize: '150%'}}/> */}
                        {/*     </a> */}
                        {/* )} */}

                    </>
                )}


            </div>

            <div className='about center vazirlightd'>
                <p hidden> {d.getDate() + 1}</p>
                {/* <p> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p> */}
                {/* <p>  {d.toLocaleDateString("fa-IR")}</p> */}
            </div>

        </div>
    )
}

export default About
