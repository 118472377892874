
export const unis = [
    {
        key: "1",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "4628150474",
        courseName: "معماری کامپیوتر",
        offerCode: "59661661457",
        schedule: "دوشنبه 13:28-18:58",
        examDate: "03/24",
        location: "کلاس 3318",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "2",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "7000031538",
        courseName: "برنامه سازی پیشرفته",
        offerCode: "59661661817",
        schedule: "دوشنبه 07:40-12:11",
        examDate: "03/19",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "3",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "460916437 - 4628164737",
        courseName: "مهندسی نرم افزار",
        offerCode: "2030669",
        schedule: "چهارشنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "4",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "5990181603",
        courseName: "آزمایشگاه نرم افزار اداری",
        offerCode: "2030205",
        schedule: "یکشنبه 07:40-12:11",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "5",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "4628105776",
        courseName: "اصول فناوری و اطلاعات",
        offerCode: "2030634",
        schedule: "پنج شنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "6",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "2035173912",
        courseName: "آزمایشگاه مدار های منطقی ومعماری کامپیوتر",
        offerCode: "101104",
        schedule: "یکشنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "7",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "6138207659",
        courseName: "برنامه نویسی سیستمی",
        offerCode: "2030430",
        schedule: "شنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "8",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "5990181259",
        courseName: "برنامه نویسی موبایل 1",
        offerCode: "2030212",
        schedule: "چهارشنبه  07:40-12:11",
        examDate: "",
        location: "",
        tags: ""
    },
    {
        key: "9",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "5990181263",
        courseName: "برنامه نویسی موبایل 2",
        offerCode: "2030296",
        schedule: "پنج شنبه  07:40-12:11",
        examDate: "",
        location: "",
        tags: ""
    },
    {
        key: "10",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "4628153620",
        courseName: "نظریه زبان ها و ماشین ها",
        offerCode: "30163",
        schedule: "شنبه  07:40-12:11",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "11",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "7000031535",
        courseName: "مبانی کامپیوتر و برنامه سازی",
        offerCode: "59661691526",
        schedule: "سه شنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    }
]



// products.js
export const books = [
    {
        id: 1,
        title: 'کتاب آموزش React',
        image: 'images/react-book.jpg',
        price: '150,000',
        description: 'کتاب جامع آموزش React برای مبتدیان و حرفه‌ای‌ها.',
        discount: '10%',
        rating: 4.8,
        category: 'books',
    },
    {
        id: 2,
        title: 'کتاب آموزش JavaScript',
        image: 'images/js-book.jpg',
        price: '120,000',
        description: 'آموزش جامع JavaScript از مبتدی تا پیشرفته.',
        discount: '5%',
        rating: 4.6,
        category: 'books',
    },
    // سایر کتاب‌ها
];

export const articles = [
    {
        id: 3,
        title: 'مقاله بهینه‌سازی وب',
        image: 'images/web-optimization.jpg',
        price: '20,000',
        description: 'مقاله‌ای در مورد تکنیک‌های بهینه‌سازی وب‌سایت.',
        discount: '5%',
        rating: 4.5,
        category: 'articles',
    },
    {
        id: 4,
        title: 'مقاله سئو',
        image: 'images/seo-article.jpg',
        price: '15,000',
        description: 'نکات و تکنیک‌های بهبود سئو وب‌سایت.',
        discount: '10%',
        rating: 4.7,
        category: 'articles',
    },
    // سایر مقالات
];

export const videos = [
    {
        id: 5,
        title: 'دوره ویدیویی آموزش Node.js',
        image: 'images/nodejs-course.jpg',
        price: '200,000',
        description: 'دوره کامل آموزش Node.js از مقدماتی تا پیشرفته.',
        discount: '15%',
        rating: 4.9,
        category: 'videos',
    },
    {
        id: 6,
        title: 'دوره ویدیویی آموزش Python',
        image: 'images/python-course.jpg',
        price: '180,000',
        description: 'آموزش پروژه‌محور زبان برنامه‌نویسی Python.',
        discount: '20%',
        rating: 4.8,
        category: 'videos',
    },
    // سایر ویدیوها
];

export const allProducts = [...books, ...articles, ...videos];

export const categories = [
    {
        id: 'all',
        name: 'همه محصولات',
    },
    {
        id: 'books',
        name: 'کتاب‌ها',
    },
    {
        id: 'articles',
        name: 'مقالات',
    },
    {
        id: 'videos',
        name: 'ویدیوها',
    },
];
