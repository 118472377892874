import React, {useState, useEffect} from 'react';
import {Layout, Input, Button, Badge, Image, List, Row, Col} from 'antd';
import {
    ShoppingCartOutlined,
    UserOutlined,
    SearchOutlined,
    HomeOutlined,
    BookOutlined,
    FileTextOutlined,
    VideoCameraOutlined,
    AppstoreOutlined,
    ShoppingOutlined,
    ProductOutlined, TrophyOutlined, PaperClipOutlined,
} from '@ant-design/icons';
import {GiTeacher} from 'react-icons/gi';
import {useNavigate} from 'react-router-dom';
import './HeaderShop.css';
import ScrollToTopMobile from "../ScrollToTopMobile/ScrollToTopMobile";

const {Header} = Layout;

const HeaderShop = ({products, toggleCategories, showCategories, handleCategorySelect}) => {
    const [searchText, setSearchText] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [currentAdIndex, setCurrentAdIndex] = useState(0); // اضافه کردن state برای مدیریت تبلیغات
    const navigate = useNavigate();

    const categories = [
        {id: 'all', name: 'همه محصولات', icon: <AppstoreOutlined/>},
        {id: 'books', name: 'کتاب‌ها', icon: <BookOutlined/>},
        {id: 'articles', name: 'مقالات', icon: <FileTextOutlined/>},
        {id: 'videos', name: 'ویدیوها', icon: <VideoCameraOutlined/>},
    ];

    const ads = [ // لیست تبلیغات
        {
            link: '/training-path',
            text1: 'کوچینگ تخصصی برنامه نویسی',
            text2: 'دکتر اسماعیل صادقی',
            color: 'green',
            icon: <GiTeacher/>,
            fontFamily: 'Vazir, sans-serif',
            backgroundColor: '#fffae5'
        },
        {
            link: '/question',
            text1: 'تست استعدادیابی برنامه‌نویسی',
            text2: '',
            color: 'rgb(253,223,104)',
            icon: <TrophyOutlined/>,
            fontFamily: 'Vazir, sans-serif',
            backgroundColor: '#fff1e5'
        },
        {
            link: '/immigration',
            text1: 'جدیدترین مقالات',
            text2: 'مهاجرت با برنامه‌نویسی',
            color: 'blue',
            icon: <PaperClipOutlined/>,
            fontFamily: 'Lalezar, sans-serif',
            backgroundColor: '#ffe5e5'
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
        }, 5000); // تغییر هر 5 ثانیه یک بار

        return () => clearInterval(interval); // پاک کردن تایمر در هنگام کامپوننت unmount
    }, [ads.length]);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchText(value);

        if (value.trim() === '') {
            setFilteredProducts([]);
            return;
        }

        const results = products.filter(
            (product) =>
                product.title.includes(value) ||
                product.description.includes(value) ||
                product.price.includes(value)
        );
        setFilteredProducts(results);
    };

    const handleSelectProduct = (productId) => {
        setSearchText('');
        setFilteredProducts([]);
        navigate(`/product/${encodeURIComponent(productId)}`);
    };

    const handlePressEnter = () => {
        setFilteredProducts([]);
        navigate(`/search?query=${encodeURIComponent(searchText)}`);
        setSearchText('');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <>
            <div className="notification-bar" style={{
                fontFamily: ads[currentAdIndex].fontFamily,
                backgroundColor: ads[currentAdIndex].backgroundColor
            }}>
                <a href={ads[currentAdIndex].link}>
                    <span style={{color: ads[currentAdIndex].color, fontSize: 12}}>
                        {ads[currentAdIndex].icon}{' '}
                    </span>
                    <span className="black-text">{ads[currentAdIndex].text1} </span>
                    <span style={{color: ads[currentAdIndex].color}}>{ads[currentAdIndex].text2} </span>
                </a>
            </div>
            <Header className="app-header">
                <div className="logo-search-container">
                    <div
                        className="logo-store"
                        onClick={handleLogoClick}
                        style={{cursor: 'pointer'}}
                    >
                        <Image
                            src="https://biaupload.com/do.php?imgf=org-03e280734a4a1.jpg"
                            alt="Logo"
                            preview={false}
                            height={40}
                        />
                    </div>
                    <div
                        className="store-name"
                        onClick={handleLogoClick}
                        style={{cursor: 'pointer'}}
                    >
                        آنلاین‌شاپ
                    </div>
                    <div className="search-section" style={{position: 'relative'}}>
                        <Input
                            placeholder="جستجو کنید..."
                            prefix={<SearchOutlined style={{color: '#bfbfbf'}}/>}
                            onChange={handleSearchChange}
                            onPressEnter={handlePressEnter}
                            className="search-input-custom"
                            value={searchText}
                        />
                        {searchText && filteredProducts.length > 0 && (
                            <div className="search-results-list">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={filteredProducts}
                                    renderItem={(product) => (
                                        <List.Item onClick={() => handleSelectProduct(product.id)}>
                                            <List.Item.Meta
                                                title={product.title}
                                                description={`قیمت: ${product.price}`}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="actions-section">
                    <Button
                        type="link"
                        icon={<UserOutlined/>}
                        className="action-btn login-btn"
                    >
                        ورود / ثبت نام
                    </Button>
                    <Badge count={5} className="cart-icon">
                        <ShoppingCartOutlined style={{fontSize: '24px'}}/>
                    </Badge>
                </div>
            </Header>
            <div className="bottom-icons">
                <Row justify="space-around" align="middle" style={{width: '100%'}}>
                    <Col className="icon-col" xs={4}>
                        <UserOutlined
                            style={{fontSize: '24px', color: '#b8b9bd'}}
                            onClick={() => navigate('/store')}
                        />
                        <div className="icon-label">کاربر</div>
                    </Col>
                    <Col className="icon-col" xs={4}>
                        <ShoppingOutlined
                            style={{fontSize: '24px', color: '#b8b9bd'}}
                            onClick={() => navigate('/store')}
                        />
                        <div className="icon-label">فروشگاه</div>
                    </Col>
                    <Col className="icon-col" xs={4}>
                        <ShoppingCartOutlined
                            style={{fontSize: '24px', color: '#b8b9bd'}}
                            onClick={() => navigate('/store')}
                        />
                        <div className="icon-label">سبد خرید</div>
                    </Col>
                    <Col className="icon-col icon-col-category" xs={4}>
                        <ProductOutlined
                            style={{fontSize: '24px', color: '#b8b9bd'}}
                            onClick={toggleCategories}
                        />
                        <div className="icon-label">دسته‌بندی</div>
                    </Col>
                    <Col className="icon-col" xs={4}>
                        <HomeOutlined
                            style={{fontSize: '24px', color: '#b8b9bd'}}
                            onClick={() => navigate('/store')}
                        />
                        <div className="icon-label">خانه</div>
                    </Col>
                </Row>
            </div>
            {showCategories && (
                <div className="mobile-categories">
                    <List
                        dataSource={categories}
                        renderItem={(category) => (
                            <List.Item onClick={() => handleCategorySelect(category.id)} className="category-item">
                                <div className="category-icon">{category.icon}</div>
                                <div className="category-name">{category.name}</div>
                            </List.Item>
                        )}
                    />
                </div>
            )}
            <ScrollToTopMobile/>
        </>
    );
};

export default HeaderShop;
