const products = [
    {
        id: 1,
        title: "برنامه نویسی جاوا SE",
        // price: "۱,۴۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "این دوره آموزشی جامع شامل آشنایی با اصول و مبانی برنامه‌نویسی جاوا SE است. از مفاهیم پایه‌ای زبان جاوا گرفته تا برنامه‌نویسی شیءگرا، مدیریت استثناها، دستکاری رشته‌ها و ساختارهای داده‌ای مهم همگی پوشش داده شده‌اند. با این دوره، مهارت‌های لازم برای توسعه‌ی برنامه‌های کاربردی جاوا را کسب خواهید کرد",
        duration: "۶۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img924/2365/Mw7vHC.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/14vr6G6PuZi-a6XUNDelJX1HcPkmgR0mO/view?usp=drive_link"
    }, {
        id: 2,
        title: "برنامه نویسی جاوا EE",
        // price: "۱,۵۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "دوره برنامه‌نویسی جاوا EE (Jakarta EE) یکی از محبوب‌ترین دوره‌های آموزشی در زمینه برنامه‌نویسی سطح بالا است. این دوره به شما امکان می‌دهد تا با مفاهیم پیشرفته و کاربردی جاوا EE آشنا شوید و مهارت‌های لازم برای توسعه برنامه‌های تحت وب مقیاس‌پذیر و کارآمد را در اختیارتان قرار دهد",
        duration: "۴۵ ساعت",
        prerequisites: "دانش در سطح دوره Java SE: آشنایی با ساختار برنامه‌های جاوا و مفاهیم اولیه وب و دیتابیس",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img924/1205/KsNqH4.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/1Fcrnf0BxO9DfKApNeu6amaV-iYLQjiGv/view?usp=drive_link"
    }, {
        id: 3,
        title: "اسپرینگ فریمورک",
        // price: "۱,۹۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "اسپرینگ یکی از محبوب‌ترین فریمورک‌های جاوا برای توسعه اپلیکیشن‌های سازمانی است. این دوره آموزشی به شما نحوه‌ی استفاده از قابلیت‌های اسپرینگ، شامل مدیریت وابستگی‌ها، AOP، اسپرینگ MVC و امنیت اسپرینگ را آموزش می‌دهد. با گذراندن این دوره، می‌توانید اپلیکیشن‌های مقیاس‌پذیر و پایدار را با استفاده از اسپرینگ توسعه دهید",
        duration: "۴۰ ساعت",
        prerequisites: "دانش در سطح دوره Java SE: آشنایی با ساختار برنامه‌های جاوا و مفاهیم اولیه وب و دیتابیس",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img924/9468/1YXwHk.jpg",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 4,
        title: "اسپرینگ بوت",
        // price: "۱,۸۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "اسپرینگ بوت به شما امکان توسعه سریع و ساده‌ی اپلیکیشن‌های جاوا را می‌دهد. این دوره آموزشی به شما نحوه‌ی پیکربندی و ایجاد پروژه‌های اسپرینگ بوت، استفاده از دیتابیس‌ها و امنیت را آموزش می‌دهد. با گذراندن این دوره، می‌توانید اپلیکیشن‌های تحت وب خود را به سرعت و به آسانی توسعه و مستقر کنید",
        duration: "۳۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/641x641q50/924/KAeBoR.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/10cznZGcxTwur7Y0EVfOEpG0m-Sqld_7c/view?usp=drive_link"
    }, {
        id: 5,
        title: "معماری میکروسرویس",
        // price: "۱,۹۰۰,۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "معماری میکروسرویس روشی مدرن برای توسعه اپلیکیشن‌های مقیاس‌پذیر و انعطاف‌پذیر است. این دوره آموزشی شما را با اصول معماری میکروسرویس، طراحی و پیاده‌سازی میکروسرویس‌ها، مدیریت ارتباطات بین سرویس‌ها و ابزارهای مرتبط نظیر Docker و Kubernetes آشنا می‌کند. با این دوره، توانایی طراحی و پیاده‌سازی معماری میکروسرویس برای اپلیکیشن‌های پیچیده را خواهید داشت",
        duration: "۴۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/img922/2258/f5JHa1.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/drive/folders/1vh1o_94OMyY6Ohi-JDp1610De5Q8OhtK"
    }, {
        id: 6,
        title: "مانگودیبی",
        // price: "۱,۹۰۰,۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "این دوره برای توسعه‌دهندگان و علاقه‌مندان به پایگاه‌داده‌ها طراحی شده است که می‌خواهند به یک سطح پیشرفته در کار با MongoDB برسند. این دوره شامل مباحث تئوری و عملی است که شما را با ویژگی‌های قدرتمند این پایگاه‌داده NoSQL آشنا می‌کند",
        duration: "۳۵ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/640x640q70/923/LR1VIY.jpg",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/file/d/1XDvK_YB2s_9TJ70RkPvINvHvIksmN81p/view?usp=drive_link"
    }, {
        id: 7,
        title: "کوچینگ برنامه نویسی",
        // price: "۲,۵۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: " ساعات اولیه بسته به نیاز شما قابل تغییر است. این ساعات شامل فرآیندهای استعدادیابی، مشاوره‌های تخصصی، آموزش‌های جامع، ارتقاء مهارت‌ها، ارزیابی‌های روان‌شناختی، پروژه‌های کاربردی و آماده‌سازی کامل برای مصاحبه‌ها می‌باشد. فرآیندهای کاریابی و مهاجرت در صورت درخواست متقاضی به صورت جداگانه محاسبه می‌شوند. ",
        description: " کوچینگ برنامه‌نویسی ما برای تمامی سطوح برنامه‌نویسان طراحی شده است، از مبتدی تا حرفه‌ای. این دوره شامل جلسات فردی و گروهی با مربیان با تجربه است که شما را در هر مرحله از مسیر یادگیری‌تان همراهی می‌کنند",
        duration: "۱۵۰ ساعت ",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی و متوسط",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/654x655q70/923/DLhyk9.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 8,
        title: "دیواپس",
        // price: "۱,۹۰۰,۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "دوره دیواپس ما برای تمامی سطوح از مبتدی تا حرفه‌ای طراحی شده است. این دوره به شما مهارت‌های لازم برای ترکیب توسعه نرم‌افزار (Development) و عملیات فناوری اطلاعات (Operations) را آموزش می‌دهد. شامل مفاهیمی مانند اتوماسیون فرآیندها، مدیریت پیکربندی، استقرار پیوسته (CI/CD)، نظارت و لاگینگ، و امنیت در دیواپس. از ابزارهایی مانند Jenkins، Docker، Kubernetes، Ansible و بسیاری دیگر استفاده خواهید کرد. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک دیواپس مهندس حرفه‌ای وارد بازار کار شوید.",
        duration: "۴۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی و مدیریت سیستم‌ها",
        audience: "برنامه‌نویسان و مدیران سیستم‌ها",
        delivery: "آنلاین <span class='vertical-bar'>/</span> خصوصی",
        image: "https://imagizer.imageshack.com/v2/655x656q70/924/0s8PoL.png",
        likes: 0,
        comments: [],
        driveLink: "https://drive.google.com/drive/folders/1LO86N5jjUgW9247IHy69j2kP-GhSCzmF"
    }, {
        id: 9,
        title: "داکر",
        // price: "۱,۹۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "  دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد",
        description: "دوره جامع داکر ما برای تمامی سطوح از مبتدی تا حرفه‌ای طراحی شده است. این دوره به شما مهارت‌های لازم برای کار با داکر را آموزش می‌دهد. شامل مفاهیمی مانند ایجاد و مدیریت کانتینرها، کار با داکرفایل‌ها و ساخت ایمیج‌ها، استفاده از داکر در محیط‌های توسعه و تولید و امنیت در داکر. از ابزارهایی مانند Docker، Kubernetes، Jenkins و بسیاری دیگر استفاده خواهید کرد. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک متخصص داکر وارد بازار کار شوید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی و مدیریت سیستم‌ها",
        audience: "برنامه‌نویسان، مدیران سیستم‌ها و مهندسان DevOps",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/641x641q50/922/TPKVGi.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 10,
        title: "پایتون",
        // price: "۱,۳۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد.",
        description: "این دوره جامع شما را با اصول و مبانی برنامه‌نویسی پایتون آشنا می‌کند و تا سطوح پیشرفته ادامه می‌دهد. شامل مباحثی مانند متغیرها، ساختارهای داده، توابع، شی‌گرایی، ماژول‌ها و کتابخانه‌های مهم پایتون می‌شود. همچنین به موضوعاتی مانند علم داده، یادگیری ماشین و توسعه وب با استفاده از پایتون پرداخته خواهد شد. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا بتوانید پروژه‌های واقعی را با پایتون انجام دهید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان مبتدی تا پیشرفته، علاقه‌مندان به علم داده و یادگیری ماشین",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/642x641q50/922/soJrn3.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 11,
        title: "گیت ",
        // price: "۱,۵۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد.",
        description: "این دوره جامع شما را با اصول و مبانی کار با گیت و مدیریت نسخه‌ها آشنا می‌کند و تا سطوح پیشرفته ادامه می‌دهد. شامل مباحثی مانند ایجاد و مدیریت مخازن، استفاده از دستورات گیت، شاخه‌بندی، مدیریت تغییرات، حل تعارض‌ها، و استفاده از ابزارهای مرتبط با گیت مانند GitHub و GitLab می‌شود. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک توسعه‌دهنده با تجربه در کار با گیت وارد بازار کار شوید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای برنامه‌نویسی",
        audience: "برنامه‌نویسان، توسعه‌دهندگان نرم‌افزار، و مدیران پروژه",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/641x641q50/923/1R8cEC.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    }, {
        id: 12,
        title: "ری اکت",
        // price: "۱,۹۰۰,۰۰۰ تومان",
        price: ". تومان",
        teacher: "دکتر اسماعیل صادقی",
        info: "این قیمت بابت هر ساعت تدریس خصوصی می‌باشد.",
        description: "این دوره جامع شما را با اصول و مبانی توسعه وب با استفاده از React JS آشنا می‌کند و تا سطوح پیشرفته ادامه می‌دهد. شامل مباحثی مانند ایجاد کامپوننت‌ها، مدیریت حالت، روتینگ، ارتباط با API‌ها، و استفاده از کتابخانه‌هایی مانند Redux و React Router می‌شود. این دوره با رویکرد عملی و پروژه‌محور، شما را آماده می‌کند تا به عنوان یک توسعه‌دهنده حرفه‌ای React JS وارد بازار کار شوید.",
        duration: "۲۰ - ۵۰ ساعت",
        prerequisites: "آشنایی با مفاهیم پایه‌ای HTML، CSS و JavaScript",
        audience: "برنامه‌نویسان، توسعه‌دهندگان وب، و علاقه‌مندان به فریم‌ورک‌های جاوااسکریپت",
        delivery: "آنلاین / خصوصی",
        image: "https://imagizer.imageshack.com/v2/640x641q50/922/rRXxsJ.png",
        likes: 0,
        comments: [],
        driveLink: "#"
    },
    // {
    //     id: ,
    //     title: "",
    //     price: "۱,۹۰۰,۰۰۰ تومان",
    //     teacher: "  دکتر اسماعیل صادقی",
    //     info: " ",
    //     description: " ",
    //     duration: "۲۰ - ۵۰ ساعت",
    //     prerequisites: " ",
    //     audience: " ",
    //     delivery: "آنلاین / خصوصی",
    //     image: " ",
    //     likes: 0,
    //     comments: [],
    //     driveLink: "#"
    // }
]

const unis = [
    {
        key: "1",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "4628150474",
        courseName: "معماری کامپیوتر",
        offerCode: "59661661457",
        schedule: "دوشنبه 13:28-18:58",
        examDate: "03/24",
        location: "کلاس 3318",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "2",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "7000031538",
        courseName: "برنامه سازی پیشرفته",
        offerCode: "59661661817",
        schedule: "دوشنبه 07:40-12:11",
        examDate: "03/19",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "3",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "460916437 - 4628164737",
        courseName: "مهندسی نرم افزار",
        offerCode: "2030669",
        schedule: "چهارشنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "4",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "5990181603",
        courseName: "آزمایشگاه نرم افزار اداری",
        offerCode: "2030205",
        schedule: "یکشنبه 07:40-12:11",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "5",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "4628105776",
        courseName: "اصول فناوری و اطلاعات",
        offerCode: "2030634",
        schedule: "پنج شنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "6",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "2035173912",
        courseName: "آزمایشگاه مدار های منطقی ومعماری کامپیوتر",
        offerCode: "101104",
        schedule: "یکشنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "7",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "6138207659",
        courseName: "برنامه نویسی سیستمی",
        offerCode: "2030430",
        schedule: "شنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "8",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "5990181259",
        courseName: "برنامه نویسی موبایل 1",
        offerCode: "2030212",
        schedule: "چهارشنبه  07:40-12:11",
        examDate: "",
        location: "",
        tags: ""
    },
    {
        key: "9",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "5990181263",
        courseName: "برنامه نویسی موبایل 2",
        offerCode: "2030296",
        schedule: "پنج شنبه  07:40-12:11",
        examDate: "",
        location: "",
        tags: ""
    },
    {
        key: "10",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "4628153620",
        courseName: "نظریه زبان ها و ماشین ها",
        offerCode: "30163",
        schedule: "شنبه  07:40-12:11",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    },
    {
        key: "11",
        group: "گروه آموزش کامپیوتر و فناوری اطلاعات",
        courseCode: "7000031535",
        courseName: "مبانی کامپیوتر و برنامه سازی",
        offerCode: "59661691526",
        schedule: "سه شنبه 13:28-18:58",
        examDate: "",
        location: "",
        tags: "نوع ارائه: حضوری, مقطع ارائه: کارشناسی"
    }
]
// eslint-disable-next-line import/prefer-default-export
export {products, unis}