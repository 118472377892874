import "./AboutMe.css";
import React, {useContext, useState} from "react";
import Header from "../Header/Header";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Footer from "../Footer/Footer";
import {ThemeContext} from "../../contexts/theme";
import {about} from "../../portfolio";

const AboutMe = () => {
    const [{themeName}] = useContext(ThemeContext);
    const [isOpen, setIsOpen] = useState(false);
    const d = new Date();
    const options = {weekday: "long", month: "long", day: "numeric"};
    const option = {year: "numeric"};

    const toggleSection = () => {
        setIsOpen(!isOpen);
    };

    const {resume} = about


    return (
        <div id="top" className={`${themeName} app`}>
            <Header/>

            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>

                <div className="about-section">
                    <img className="avatar" src="https://biaupload.com/do.php?imgf=org-4f37948307931.jpeg"
                         alt="About me"/>
                    <div className="about-text">
                        {/* <h1 className="bTitrShop">درباره گروه آموزشی ما</h1> */}
                        <h1 className="bTitrShop">درباره گروه آموزشی دکتر اسماعیل صادقی</h1>
                        <p className="bNazanintdrDA">به گروه آموزشی دکتر اسماعیل صادقی خوش آمدید. ما با افتخار به ارائه
                            آموزش‌های تخصصی و با کیفیت برای دانشجویان و علاقه‌مندان به یادگیری مشغول هستیم. در طول
                            سال‌ها، گروه آموزشی ما دستاوردهای متعددی در زمینه آموزش و پژوهش کسب کرده است. این دستاوردها
                            حاصل تلاش‌های مستمر و تعهد ما به کیفیت آموزش است.
                            تیم ما متشکل از متخصصین و اساتیدی با تجربه در حوزه‌های مختلف است که هر یک به طور اختصاصی در
                            زمینه‌های خود مشغول به تدریس و پژوهش هستند. این تخصص‌ها شامل برنامه‌نویسی، تحلیل داده‌ها،
                            توسعه وب، امنیت سایبری، بلاکچین و یادگیری ماشین می‌باشد. اعضای تیم ما دارای تجربه‌های
                            گسترده‌ای در پروژه‌های ملی و بین‌المللی بوده و همواره در صدد به‌روز نگه‌داشتن دانش و
                            مهارت‌های خود هستند.
                            ما در این گروه آموزشی به ارائه دوره‌های آموزشی با رویکرد عملی و کاربردی اعتقاد داریم. هدف ما
                            این است که دانشجویان و علاقه‌مندان بتوانند با استفاده از مهارت‌های کسب شده در دوره‌هایمان،
                            به موفقیت‌های شغلی و حرفه‌ای دست یابند. همچنین، تیم ما همواره آماده ارائه مشاوره و
                            راهنمایی‌های فردی برای کمک به دانشجویان در مسیر یادگیری و توسعه شغلی‌شان می‌باشد.
                            در کنار آموزش‌های تخصصی، ما به پژوهش و نوآوری نیز اهمیت ویژه‌ای می‌دهیم. با همکاری با مراکز
                            تحقیقاتی و دانشگاه‌های معتبر، پروژه‌های پژوهشی مختلفی را انجام داده‌ایم و نتایج آن‌ها را با
                            جامعه علمی و صنعتی به اشتراک گذاشته‌ایم. </p>

                        <div className='about__contact center'>
                            {resume && (
                                <a href={resume}>
                                        <span type='button' className='btn btn--outline vazirlight'>
                                          رزومه کاری
                                        </span>
                                </a>
                            )}
                        </div>

                    </div>
                </div>

                <div className="history-section">
                    <h2 className="bTitrShop">تاریخچه</h2>
                    <p className="bNazanintdrDA">ما از سال ۲۰۰۵ با ارائه آموزش‌های مرتبط با برنامه‌نویسی گام‌های نخست
                        خود را برداشتیم. پس از مدت کوتاهی، وارد پروژه‌های برنامه‌نویسی شدیم و به صورت گام به گام، با
                        شرکت‌های داخلی و خارجی وارد کار، مشاوره و آموزش شدیم.</p>
                    <p className="bNazanintdrDA">هم اکنون افتخار داریم که به عنوان پیشتاز کوچینگ برنامه‌نویسی در ایران
                        فعالیت خود را ادامه می‌دهیم. مدیریت مجموعه ما، جناب آقای دکتر اسماعیل صادقی، مدرس دانشگاه و بوت
                        کمپ و برگزارکننده دوره‌های مختلفی در سطح تهران به صورت عمومی و خصوصی می‌باشند.</p>
                    <p className="bNazanintdrDA">ما اعتقاد داریم که دانش، ماندگارترین ابزار تغییر است و می‌خواهیم با
                        تمرکز بر گسترش سطوح آموزش دانشگاهی، نقش خود را در مسیر تغییر ایفا کنیم. ما یک دانشگاه تخصصی
                        هستیم که تمرکز ما بر روی برنامه‌نویسی، آموزش و انجام پروژه‌های مربوط به آن می‌باشد.</p>
                </div>


                {/* eslint-disable-next-line react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span className="toggle-text" onClick={toggleSection}>
                 {isOpen ? "بستن ..." : " ... نمایش بیشتر"}
                </span>

                {isOpen && (
                    <div className="toggle-content">
                        <section>
                            <h2 className="bTitrShopS">معرفی تیم</h2>
                            <p className="bNazanintdrDA">تیم آموزشی ما تحت مدیریت دکتر اسماعیل صادقی، متشکل از متخصصینی
                                با تجربه و دانش بالا در حوزه‌های مختلف آموزشی و پژوهشی است. اعضای تیم ما در زمینه‌های
                                گوناگونی از جمله برنامه‌نویسی، تحلیل داده‌ها، توسعه وب، امنیت سایبری، بلاکچین و یادگیری
                                ماشین تخصص دارند. هر یک از اعضای تیم ما با پشتوانه سال‌ها تجربه و شرکت در پروژه‌های ملی
                                و بین‌المللی، توانایی‌ها و مهارت‌های برجسته‌ای دارند که به ارتقاء کیفیت آموزش‌های ما کمک
                                می‌کند. تیم ما با تعهد به آموزش با کیفیت و عملی، تلاش می‌کند تا
                                دانشجویان و علاقه‌مندان به یادگیری، بهترین و به‌روزترین مطالب را در دوره‌های آموزشی
                                دریافت کنند. ما به توسعه شغلی و موفقیت تحصیلی دانشجویان خود اهمیت می‌دهیم و همواره آماده
                                ارائه مشاوره‌های فردی و گروهی برای کمک به آنان در مسیر یادگیری و حرفه‌ای شدن هستیم.</p>
                        </section>

                        <section>
                            <h2 className="bTitrShopS">خدمات کوچینگ ما</h2>
                            <p className="bNazanintdrDA">ما در زمینه‌های گوناگونی خدمات کوچینگ و مشاوره‌ای ارائه می‌دهیم
                                که شامل:</p>
                            <ul className="custom-list">
                                <li>استعدادیابی</li>
                                <li>مشاوره</li>
                                <li>آموزش برنامه‌نویسی</li>
                                <li>کاریابی</li>
                                <li>مهاجرت</li>
                            </ul>
                            <p className="bNazanintdrDA">در کوچینگ برنامه‌نویسی، ما به دانشجویان کمک می‌کنیم تا
                                مهارت‌های خود را در برنامه‌نویسی
                                تقویت کنند و در این مسیر به موفقیت دست یابند. این فرآیند شامل جلسات مشاوره فردی و گروهی،
                                پروژه‌های عملی، تمرین‌های متنوع و ارزیابی‌های مستمر است. هدف ما این است که دانشجویان
                                بتوانند با استفاده از مهارت‌های کسب شده، به طور مؤثر و حرفه‌ای در بازار کار فعالیت کنند.
                                همچنین، ما به دانشجویان کمک می‌کنیم تا با استفاده از تجربیات و دانش ما، مسیری مناسب برای
                                پیشرفت شغلی و تحصیلی خود انتخاب کنند.</p>
                        </section>

                        {/* <section> */}
                        {/*     <h2>تجربه‌های حرفه‌ای</h2> */}
                        {/*     <p>تیم ما دارای تجربه‌های حرفه‌ای گسترده‌ای در پروژه‌های ملی و بین‌المللی است. این تجربیات */}
                        {/*         باعث شده تا ما بتوانیم بهترین و کارآمدترین خدمات را به دانشجویان و علاقه‌مندان ارائه */}
                        {/*         دهیم.</p> */}
                        {/* </section> */}
                    </div>
                )}

                <div className="contact-section">
                    <p className="contact-text">شما می‌توانید ما را از طریق شبکه‌های اجتماعی زیر دنبال کنید و با
                        فعالیت‌های ما بیشتر آشنا شوید</p>
                    <ul className="contact-links">
                        <li><a href="https://www.linkedin.com/in/dr-esmaeil-sadeghi/">LinkedIn</a></li>
                        <li><a href="https://github.com/esmaeilsadeghijob">GitHub</a></li>
                    </ul>
                </div>



            </main>

            <ScrollToTop/>
            <Footer/>
        </div>
    );
}

export default AboutMe;
