// Shop.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderShop from './HeaderShop/HeaderShop';
import ProductList from './Product/ProductList';
import { allProducts } from '../../productsStore';
import { Tabs } from 'antd';
import './Store.css';

const { TabPane } = Tabs;

const Store = () => {
    const navigate = useNavigate();
    const { categoryId } = useParams();
    const [filteredItems, setFilteredItems] = useState(allProducts);
    const [showCategories, setShowCategories] = useState(false); // اضافه کردن وضعیت نمایش دسته‌بندی‌ها

    const toggleCategories = () => {
        setShowCategories(!showCategories);
    };

    const handleSearch = (value) => {
        const results = allProducts.filter(
            (product) =>
                product.title.includes(value) ||
                product.description.includes(value) ||
                product.price.includes(value)
        );
        setFilteredItems(results);
    };

    const handleItemClick = (item) => {
        navigate(`/product/${item.id}`);
    };

    const handleCategoryChange = (key) => {
        if (key === 'all') {
            setFilteredItems(allProducts);
        } else {
            const results = allProducts.filter((product) => product.category === key);
            setFilteredItems(results);
        }
        navigate(`/category/${key}`);
        setShowCategories(false); // مخفی کردن دسته‌بندی‌ها بعد از انتخاب دسته‌بندی
    };

    const handleCategorySelect = (key) => {
        navigate(`/category/${key}`);
        setShowCategories(false); // مخفی کردن دسته‌بندی‌ها بعد از انتخاب دسته‌بندی
    };

    useEffect(() => {
        if (categoryId) {
            if (categoryId === 'all') {
                setFilteredItems(allProducts);
            } else {
                const results = allProducts.filter((product) => product.category === categoryId);
                setFilteredItems(results);
            }
        } else {
            setFilteredItems(allProducts);
        }
    }, [categoryId]);

    return (
        <div>
            <HeaderShop
                products={allProducts}
                toggleCategories={toggleCategories}
                showCategories={showCategories}
                handleCategorySelect={handleCategorySelect}
            />
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="category-tabs">
                <Tabs
                    activeKey={categoryId || 'all'}
                    onChange={handleCategoryChange}
                    centered
                >
                    <TabPane tab="همه محصولات" key="all" />
                    <TabPane tab="کتاب‌ها" key="books" />
                    <TabPane tab="مقالات" key="articles" />
                    <TabPane tab="ویدیوها" key="videos" />
                </Tabs>
            </div>
            <ProductList products={filteredItems} onItemClick={handleItemClick} />
        </div>
    );
};

export default Store;
