import React from 'react'; // Add this import
import "./Service.css"
import {useContext} from "react";
import { Button, Result } from 'antd';
import {useNavigate} from "react-router-dom";
import {ThemeContext} from "../../contexts/theme";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const Service = () => {

    const [{themeName}] = useContext(ThemeContext)
    const d = new Date();
    const options = {weekday: 'long', month: 'long', day: 'numeric'};
    const option = {year: 'numeric'};
    const navigate = useNavigate();

    return (
        <div id='top' className={`${themeName} app`}>
            <Header/>
            <main>
                <p className="bNazanintd"> امروز {d.toLocaleDateString("fa-IR", options)} - {d.toLocaleDateString("fa-IR", option)}</p>

                <Result
                    status="403"
                    title={<span className="bTitr">۴۰۳</span>}
                    subTitle={<span className="vazir">با عرض پوزش، شما مجاز به دسترسی به این صفحه نیستید</span>}
                    extra={
                        <Button
                            type="link"
                            className="bNazanintdrD4"
                            onClick={() => navigate('/')}
                        >
                            برگشت به صفحه اصلی
                        </Button>
                    }
                />

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </main>
            <ScrollToTop/>
            <Footer/>
        </div>
    );
}

export default Service