import {
    FileProtectOutlined,
    HomeOutlined,
    MobileOutlined,
    ShopOutlined,
    TrophyOutlined,
    UserAddOutlined,
    WhatsAppOutlined,
    SoundOutlined,
    AudioMutedOutlined,
    MutedOutlined
} from '@ant-design/icons';
import {header} from '../../portfolio'
import './Header.css'
import NavbarPage from "../Navbar/NavbarPage";
import React, {useRef, useState} from "react";
import Navbar from "../Navbar/Navbar";

const HeaderPage = () => {
    const {homepage, title} = header;
    const [isMuted, setIsMuted] = useState(true);
    const audioRef = useRef(null);

    const toggleMute = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isMuted) {
                audio.muted = false;
                setIsMuted(false);
            } else {
                audio.muted = true;
                setIsMuted(true);
            }
        }
    };

    return (
        <header className='header center'>
            <audio ref={audioRef}
                   src="https://malekpoursuccess.com/wp-content/uploads/2021/12/Ali-toygar-roots2.mp3"
                   autoPlay loop muted/>
            <div className='header-left'>
                {homepage ? (
                    <a href={homepage} className='link'>
                        {/* {title} */}
                        <HomeOutlined/>
                    </a>
                ) : (
                    title
                )}
                <span className='space'/>
                <button onClick={toggleMute} className='link' title="پخش موزیک">
                    {isMuted ? <MutedOutlined/> : <SoundOutlined/>}
                </button>
            </div>
            <div className='header-right'>
                <Navbar/>
            </div>
        </header>
    )
}

export default HeaderPage
