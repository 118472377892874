import "./Uni.css";
import { useContext, useState } from "react";
import { Table, Input, Image, Layout, Divider } from "antd";
import { ThemeContext } from "../../contexts/theme";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { unis } from "../../products";

const { Content } = Layout;

const Uni = () => {
    const [{ themeName }] = useContext(ThemeContext);
    const [searchText, setSearchText] = useState("");

    const columns = [
        { title: "برچسب ها", dataIndex: "tags", key: "tags", className: "hide-column" },
        { title: "مكان برگزاری", dataIndex: "location", key: "location", className: "show-column" },
        { title: "تاريخ امتحان", dataIndex: "examDate", key: "examDate", className: "hide-column" },
        { title: "زمانبندي تشکيل کلاس", dataIndex: "schedule", key: "schedule", className: "show-column" },
        { title: "نام درس", dataIndex: "courseName", key: "courseName", className: "show-column" },
        { title: "كد ارائه", dataIndex: "offerCode", key: "offerCode", className: "hide-column" },
        { title: "گروه آموزشی", dataIndex: "group", key: "group", className: "hide-column" }
    ];

    const data = unis;

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredData = data.filter(item => Object.keys(item).some(key =>
        String(item[key]).toLowerCase().includes(searchText.toLowerCase())
    ));

    return (
        <Layout className={`${themeName} app`}>
            <Header />
            <Content>
                <div className="header-section">
                    <Image src="https://biaupload.com/do.php?imgf=org-789d9859cbd61.png" alt="لوگو" className="logo" preview={false} />
                    <div className="title-section">
                        <h1 className="title">پرتال دانشجویی</h1>
                        <h2 className="subtitle">دکتر اسماعیل صادقی</h2>
                        <h3 className="description">کتاب‌ها، جزوه‌ها و اسناد مربوط به درس‌ها</h3>
                    </div>
                </div>
                <div className="table-section">
                    <Input
                        placeholder="...جستجو"
                        onChange={handleSearch}
                        className="search-bar"
                    />
                    <div className="spacing" />
                    <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 10 }} className="center-align-table" />
                </div>
                <Divider />
                <div className="footer-links">
                    <table className="logo-table">
                        <tbody>
                        <tr>
                            <td>
                                <a href="https://etb.iau.ir/fa" target="_blank" rel="noopener noreferrer">
                                    <Image src="https://akhbarelmi.ir/media/logo/281_5.png" alt="تهران شرق" preview={false} className="logo-image" />
                                    <p className="nastaliq small-text">سایت دانشگاه تهران شرق</p>
                                </a>
                            </td>
                            <td>
                                <a href="https://stb.iau.ir/fa" target="_blank" rel="noopener noreferrer">
                                    <Image src="https://cdn.wikibin.ir/wiki-images-stage/b84d05e1ae9f62ed8b860a8b17235ba5.png" alt="تهران جنوب" preview={false} className="logo-image" />
                                    <p className="nastaliq small-text">سایت تهران جنوب</p>
                                </a>
                            </td>
                            <td>
                                <a href="https://iau.ir/" target="_blank" rel="noopener noreferrer">
                                    <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNvQ5SSQRAkRFyBS6HcZMqKNm0IX0y9o8KCg&s" alt="دانشگاه آزاد" preview={false} className="logo-image" />
                                    <p className="nastaliq small-text">سایت دانشگاه آزاد</p>
                                </a>
                            </td>
                            <td>
                                <a href="https://yi.iau.ir/" target="_blank" rel="noopener noreferrer">
                                    <Image src="https://www.uniref.ir/media/lo/1353.webp" alt="یادگار امام" preview={false} className="logo-image" />
                                    <p className="nastaliq small-text">سایت دانشگاه یادگار امام</p>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Content>
            <Footer />
            <ScrollToTop />
        </Layout>
    );
};

export default Uni;
