import {render} from 'react-dom';
import App from './App';
import './index.css';

// if ('serviceWorker' in navigator) {
//     window.addEventListener('load', () => {
//         navigator.serviceWorker.register('/service-worker.js').then((registration) => {
//             console.log('ServiceWorker registration successful with scope: ', registration.scope);
//         }, (error) => {
//             console.log('ServiceWorker registration failed: ', error);
//         });
//     });
// }

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
}

render(
    <App/>,
    document.getElementById('root')
);
