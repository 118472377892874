import {Alert} from 'antd';
import Marquee from 'react-fast-marquee';
import './AlertMessage.css';
import {LaptopOutlined, SmileOutlined} from "@ant-design/icons";

const AlertMessage = () => (
    <Alert
        banner
        message={
            <Marquee pauseOnHover gradient={false} direction="right" className="bNazanintMessTitleM" speed={35}>
                کوچینگ برنامه‌نویسی: بهترین راه برای تسلط به مهارت‌های جدید &nbsp; &nbsp; &nbsp; &nbsp;
                از تجربه مربیان ما بهره‌مند شوید و سریع‌تر پیشرفت کنید! &nbsp; &nbsp; &nbsp; &nbsp;
                متخصص در طراحی و توسعه نرم‌افزارهای سفارشی &nbsp; &nbsp; &nbsp; &nbsp;
                ارائه راه‌حل‌های نوآورانه برای بهبود بهره‌وری سازمانی &nbsp; &nbsp; &nbsp; &nbsp;
                برگزاری کارگاه‌های آموزشی پیشرفته در زمینه تکنولوژی‌های روز &nbsp; &nbsp; &nbsp; &nbsp;
                ارائه مشاوره در مدیریت پروژه‌های پیچیده نرم‌افزاری &nbsp; &nbsp; &nbsp; &nbsp;
                راهنمایی و پشتیبانی در مهاجرت به سیستم‌های ابری و تکنولوژی‌های مدرن &nbsp; &nbsp; &nbsp; &nbsp;
                مشاوره در انتخاب و پیاده‌سازی ابزارهای توسعه و عملیات &nbsp; &nbsp; &nbsp; &nbsp;
                بهره‌گیری از تکنولوژی بلاکچین برای ایجاد سامانه‌های امن و شفاف &nbsp; &nbsp; &nbsp; &nbsp;
                معماری میکروسرویس برای افزایش مقیاس‌پذیری و انعطاف‌پذیری سیستم‌ها
            </Marquee>


        }
        // icon={<SmileOutlined/>}
        icon={<LaptopOutlined/>}
        className="custom-alert"
    />
);

export default AlertMessage;


// import {Alert} from 'antd';
// import Marquee from 'react-fast-marquee';
//
// const AlertMessage = () => (
//     <Alert
//         banner
//         message={
//             <Marquee pauseOnHover gradient={false}>
//                 I can be a React component, multiple React components, or just some text.
//             </Marquee>
//         }
//     />
// );
//
// export default AlertMessage;