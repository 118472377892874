import './Footer.css'

const Footer = () => (
    <footer className='footer'>
        <div className='copyr'>
            <p className='underline'><span>ESMAIL SADEGHI HAFSHEJANI</span></p>
            <p className='underline'><span>PRIVACY POLICY</span> | <span>TERMS OF USE</span></p>
            <p className='underline'><span>YOUR PRIVACY RIGHTS</span> | <span>ONLINE PRIVACY POLICY</span></p>
        </div>
        <p className='copyrb'>© 1998 - 2020 JAVATAR | +98 21 66353207</p>

    </footer>
)

export default Footer
