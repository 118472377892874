import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from './contexts/theme';
import './App.css';
import Home from "./components/Home/Home";
import Automaton from "./components/Pages/Automaton/Automaton";
import Java from "./components/Pages/Java/Java";
import Question from "./components/Pages/Questions/Question";
import Signup from "./components/Pages/User/Signup";
import EduShop from "./components/Edu/EduShop";
import CoachingPath from "./components/Pages/Training/CoachingPath";
import AboutMe from "./components/About/AboutMe";
import Architecture from "./components/Pages/Architecture/Architecture";
import Service from "./components/Services/Service";
import Uni from "./components/Uni/Uni";
import Immigration from "./components/Blog/Immigration";
import CoachingBlog from "./components/Blog/CoachingBlog";
import Store from "./components/Store/Store";
import ProductDetail from "./components/Store/Product/ProductDetail";
import SearchResults from "./components/Store/Search/SearchResults";

function App() {
    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/automaton" element={<Automaton/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/java" element={<Java/>}/>
                    <Route path="/shop" element={<EduShop/>}/>
                    <Route path="/question" element={<Question/>}/>
                    <Route path="/training-path" element={<CoachingPath/>}/>
                    <Route path="/aboutme" element={<AboutMe/>}/>
                    <Route path="/architecture" element={<Architecture/>}/>
                    <Route path="/service" element={<Service/>}/>
                    <Route path="/uni" element={<Uni/>}/>
                    <Route path="/immigration" element={<Immigration/>}/>
                    <Route path="/coachingblog" element={<CoachingBlog/>}/>
                    <Route path="/store" element={<Store/>}/>
                    <Route path="/category/:categoryId" element={<Store />} />
                    <Route path="/product/:productId" element={<ProductDetail />} />
                    <Route path="/search" element={<SearchResults />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
