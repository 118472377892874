import React, {useContext, useState} from "react";
import {
    HeartOutlined, HeartFilled, ShareAltOutlined, LinkOutlined, ShoppingCartOutlined
} from '@ant-design/icons';
import {notification, ConfigProvider, Image, Input} from 'antd';
import {ThemeContext} from "../../contexts/theme";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Footer from "../Footer/Footer";
import './EduShop.css';
import Header from "../Header/Header";
import {products} from '../../products'

// eslint-disable-next-line no-shadow
const searchProducts = (searchTerm, products) => {
    if (!searchTerm) {
        return products; // If search term is empty, return all products
    }
    return products.filter(product =>
        Object.values(product).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
};

const EduShop = () => {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
        }).catch((err) => {
        });
    };

    const [{themeName}] = useContext(ThemeContext);
    const [productList, setProductList] = useState(products);
    const [likedProducts, setLikedProducts] = useState({});
    const [expandedProductId, setExpandedProductId] = useState(null);
    const [notifyCount, setNotifyCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredProducts, setFilteredProducts] = useState(products);
    const {Search} = Input;

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        const results = searchProducts(term, products);
        setFilteredProducts(results);
    };

    const handleLikeToggle = (productId) => {
        const isLiked = likedProducts[productId];
        const updatedProducts = productList.map(product => {
            if (product.id === productId) {
                return {...product, likes: isLiked ? product.likes - 1 : product.likes + 1};
            }
            return product;
        });
        setProductList(updatedProducts);
        setLikedProducts({...likedProducts, [productId]: !isLiked});
    };

    const handleCommentLike = (productId, commentId) => {
        const updatedProducts = productList.map(product => {
            if (product.id === productId) {
                const updatedComments = product.comments.map(comment => {
                    if (comment.id === commentId) {
                        return {...comment, likes: comment.likes + 1};
                    }
                    return comment;
                });
                return {...product, comments: updatedComments};
            }
            return product;
        });
        setProductList(updatedProducts);
    };

    const handleReply = (productId, commentId, replyText) => {
        const updatedProducts = productList.map(product => {
            if (product.id === productId) {
                const updatedComments = product.comments.map(comment => {
                    if (comment.id === commentId) {
                        const newReply = {id: Date.now(), text: replyText, likes: 0};
                        return {...comment, replies: [...comment.replies, newReply]};
                    }
                    return comment;
                });
                return {...product, comments: updatedComments};
            }
            return product;
        });
        setProductList(updatedProducts);
    };

    const toggleMore = (productId) => {
        setExpandedProductId(expandedProductId === productId ? null : productId);
    };

    const handleShoppingCartClick = () => {
        notification.error({
            message: 'خطا', description: 'در حال حاضر ارتباط با درگاه پرداخت مقدور نمی باشد.', duration: 3, // مدت زمان نمایش نوتیفیکیشن (3 ثانیه)
            placement: 'topLeft', // موقعیت نمایش نوتیفیکیشن
            className: 'notification-rtl' // کلاس برای راست چین کردن نوتیفیکیشن و تنظیم فونت
        });
        setNotifyCount(notifyCount + 1);
    };

    return (
        <ConfigProvider direction="rtl">
            <div id='top' className={`${themeName} app`}>
                <Header/>
                <main>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder=" جستجو   "
                            value={searchTerm}
                            onChange={handleSearch}
                            className="search-input"
                        />
                    </div>
                    <div className='product-list'>
                        {filteredProducts.map(product => (
                            <div key={product.id} className='product-card'>
                                <img src={product.image} alt={product.title} className='product-image'/>
                                <h2 className="lalezarShop">
                                    {product.title}
                                    <span className="price"><span
                                        style={{fontSize: 'smaller', opacity: 0.5, marginLeft: '5px'}}> (خصوصی)
                                    </span>{product.price}</span>
                                </h2>
                                {product.description && (
                                    <>
                                        <p className={`bNazanintdrD ${expandedProductId === product.id ? '' : 'product-description'}`}
                                           style={{marginBottom: '20px'}}>
                                            {expandedProductId === product.id && product.description}
                                        </p>
                                        {expandedProductId === product.id && (
                                            <>
                                                <p style={{marginBottom: '3px'}}>
                                                    <span className="bNazanintdrDB">مدرس دوره:</span>
                                                    <span className="bNazanintdrD">{product.teacher}</span>
                                                </p>
                                                <p style={{marginBottom: '3px'}}>
                                                    <span className="bNazanintdrDB">مدت زمان:</span>
                                                    <span className="bNazanintdrD">{product.duration}</span>
                                                </p>
                                                {product.prerequisites && (
                                                    <p style={{marginBottom: '3px'}}>
                                                        <span className="bNazanintdrDB">پیش‌نیاز:</span>
                                                        <span className="bNazanintdrD">{product.prerequisites}</span>
                                                    </p>
                                                )}
                                                {product.audience && (
                                                    <p style={{marginBottom: '3px'}}>
                                                        <span className="bNazanintdrDB">مخاطبان دوره:</span>
                                                        <span className="bNazanintdrD">{product.audience}</span>
                                                    </p>
                                                )}
                                                {product.delivery && (
                                                    <p style={{marginBottom: '3px'}}>
                                                        <span className="bNazanintdrDB">نحوه برگزاری:</span>
                                                        <span className="bNazanintdrD"
                                                              dangerouslySetInnerHTML={{__html: product.delivery}}/>
                                                    </p>
                                                )}
                                                {product.info && (
                                                    <p style={{marginBottom: '3px', textAlign: 'justify'}}>
                                                        <span className="bNazanintdrDB">توضیحات:</span>
                                                        <span className="bNazanintdrD">{product.info}</span>
                                                    </p>
                                                )}
                                            </>
                                        )}
                                        {expandedProductId !== product.id && (
                                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                            <span className="more-button" onClick={() => toggleMore(product.id)}> ... more </span>
                                        )}
                                    </>
                                )}
                                <div className='product-actions'>
                                    {likedProducts[product.id] ? (
                                        <HeartFilled style={{color: 'red'}}
                                                     onClick={() => handleLikeToggle(product.id)}/>
                                    ) : (
                                        <HeartOutlined onClick={() => handleLikeToggle(product.id)}/>
                                    )} {product.likes}
                                    <ShareAltOutlined style={{marginRight: '10px'}}
                                                      onClick={() => copyToClipboard(`${window.location.href}product/${product.id}`)}/>
                                    <a href={product.driveLink} target="_blank" rel="noopener noreferrer">
                                        <LinkOutlined style={{marginRight: '10px'}}/>
                                    </a>
                                    <ShoppingCartOutlined
                                        style={{marginRight: '10px'}}
                                        onClick={handleShoppingCartClick}
                                    />
                                </div>
                                <div className='product-comments'>
                                    {product.comments.map(comment => (
                                        <div key={comment.id}
                                             className='product-comment'>
                                            <p>{comment.text}</p>
                                            <div className='comment-actions'>
                                                <HeartOutlined
                                                    onClick={() => handleCommentLike(product.id, comment.id)}
                                                /> {comment.likes}
                                                {/* eslint-disable-next-line react/button-has-type */}
                                                <button onClick={() => handleReply(product.id, comment.id, "پاسخ")}>
                                                    Reply
                                                </button>
                                            </div>
                                            {comment.replies.map(reply => (
                                                <div key={reply.id} className='comment-reply'>
                                                    <p>{reply.text}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </main>
                <ScrollToTop/>
                <Footer/>
            </div>
        </ConfigProvider>
    );
};

export default EduShop;
